/* components.module.css */

/* Layout Components */
.container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  /* Add padding here instead */
  padding: 20px;

}

.card, 
.dashboardCard, 
.contentCard {
  background-color: var(--color-background);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
}


.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.mainContent {
  max-width: 800px;
  width: 100%;
  padding: 0 var(--spacing-md);
  box-sizing: border-box;
}
/* Header Styles */
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-lg);
}

.title {
  font-size: var(--font-size-xl);
  font-weight: 600;
  color: var(--color-text-primary);
  margin: 0 0 var(--spacing-sm) 0;
}

.description {
  color: var(--color-text-secondary);
  font-size: var(--font-size-sm);
  margin: 0;
}

/* Status and Alerts */
.activeRequests {
  display: flex;
  align-items: center;
  font-size: var(--font-size-base);
  color: var(--color-text-secondary);
}

.alert {
  background-color: var(--color-error-light);
  color: var(--color-error);
  padding: var(--spacing-sm) var(--spacing-md);
  margin-bottom: var(--spacing-lg);
  border: 1px solid var(--color-error);
  border-radius: var(--radius-md);
}

.error {
  composes: alert;
  margin-bottom: 0;
}

/* Card Component */
.card {
  background-color: var(--color-background);
  border: 1px solid var(--color-border);
  border-radius: var(--radius-lg);
  padding: var(--spacing-lg);
  box-shadow: var(--shadow-md);
}

/* Button Base Styles */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-md);
  font-size: var(--font-size-sm);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

/* Button Variants */
.buttonPrimary {
  composes: button;
  background-color: var(--color-primary);
  color: white;
  border: none;
}

.buttonPrimary:hover {
  background-color: var(--color-primary-dark);
}

.buttonSecondary {
  composes: button;
  background-color: var(--color-secondary);
  color: var(--color-text-primary);
  border: 1px solid var(--color-border);
}

.buttonSecondary:hover {
  background-color: var(--color-border);
}

.buttonOutline {
  composes: button;
  background-color: transparent;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}

.buttonDefault {
  composes: buttonPrimary;
}

/* Dialog/Modal Styles */
.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog {
  background: var(--color-background);
  border-radius: var(--radius-lg);
  padding: var(--spacing-lg);
  width: 100%;
  max-width: 400px;
  position: relative;
  box-shadow: var(--shadow-lg);
}

.header {
  margin-bottom: var(--spacing-lg);
}

/* Layout Utilities */
.flex {
  display: flex;
}

.flexCol {
  flex-direction: column;
}

.gap1 {
  gap: var(--spacing-xs);
}

.gap2 {
  gap: var(--spacing-sm);
}

.gap4 {
  gap: var(--spacing-md);
}

.justifyBetween {
  justify-content: space-between;
}

.alignCenter {
  align-items: center;
}

@media (max-width: 768px) {
  .container,
  .mainContent {
    padding: 0 var(--spacing-sm);
  }

  .mainContainer {
    padding: var(--spacing-sm) 0;
  }
}
