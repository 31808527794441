/* CollaborationPortal.module.css */
.collaborationPortal {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: 18px;
    color: #555;
}

.errorContainer {
    max-width: 500px;
    margin: 100px auto;
    padding: 30px;
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.errorContainer h2 {
    color: #e53935;
    margin-bottom: 15px;
}

.errorContainer button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #2196f3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.errorContainer button:hover {
    background-color: #1976d2;
}