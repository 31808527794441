/* MessageInput.module.css */
.messageInput {
    background-color: #fff;
    border-top: 1px solid #e0e0e0;
    padding: 15px;
}

.attachmentPreview {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
}

.attachmentItem {
    display: flex;
    align-items: center;
    background-color: #e3f2fd;
    border-radius: 4px;
    padding: 6px 10px;
    font-size: 14px;
}

.attachmentName {
    margin-right: 10px;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.removeAttachment {
    background: none;
    border: none;
    color: #757575;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    padding: 0;
}

.removeAttachment:hover {
    color: #e53935;
}

.inputArea {
    display: flex;
    flex-direction: column;
}

.textInput {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 12px;
    font-family: inherit;
    font-size: 15px;
    resize: none;
    margin-bottom: 10px;
}

.textInput:focus {
    outline: none;
    border-color: #2196f3;
}

.textInput:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

.inputActions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.attachButton,
.sendButton {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    transition: background-color 0.2s ease;
}

.attachButton {
    background-color: #f5f5f5;
    color: #424242;
}

.attachButton:hover:not(:disabled) {
    background-color: #e0e0e0;
}

.sendButton {
    background-color: #2196f3;
    color: white;
}

.sendButton:hover:not(:disabled) {
    background-color: #1976d2;
}

.attachButton:disabled,
.sendButton:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.fileInput {
    display: none;
}